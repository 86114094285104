import { FileUtils } from './../utils/FileUtils';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../contexts/ToastContext';
import DateUtils from '../utils/DateUtils';
import DataExportService from '../services/DataExportService';
import { GenerateExampleFileRequest } from '../models/DataExport';
import JSZip from 'jszip';
import i18next from 'i18next';

export const useDownloadImportExample = () => {
  const toasts = useToasts();
  const { t } = useTranslation(['common', 'data-import-export']);
  const addRemoteFileToZip = useCallback((remoteFilePath: string, zip: JSZip) => {
    return fetch(remoteFilePath)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = FileUtils.getFileNameFromPath(remoteFilePath);
        zip.file(fileName, blob);
      });
  }, []);

  const download = useCallback(
    (request: GenerateExampleFileRequest) => {
      toasts.addToast({ title: t('common:download-examplefile.preparing'), type: ToastType.INFO, expiresInMs: 1000 });
      DataExportService.downloadExampleFile(request)
        .then((res) => {
          const zip = new JSZip();
          // Add individual files to the ZIP
          const filename =
            `${request.templateName}_${DateUtils.formatDate(DateUtils.now)}`.replaceAll(/[^a-zA-Z0-9]/g, '_') + `.${request.fileExtension}`;

          zip.file(filename, res);

          addRemoteFileToZip(`/Playbook - Data Import Guide_${i18next.language}.pdf`, zip)
            .then(() => {
              // Generate the ZIP file asynchronously
              return zip.generateAsync({ type: 'blob' });
            })
            .then((content) => {
              const url = URL.createObjectURL(content);
              const downloadLink = document.createElement('a');
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.download = `${t('data-import-export:import.upload-file-step.download-template-filename')}.zip`;
              downloadLink.click();
              setTimeout(() => {
                URL.revokeObjectURL(url);
              }, 100);
            });
        })
        .catch(() => {
          toasts.addToast({ title: t('common:download-examplefile.error'), type: ToastType.ERROR, expiresInMs: 1000 });
        });
    },
    [addRemoteFileToZip, t, toasts],
  );

  return download;
};
