import { ApiResponse } from '../models/ApiResponse';
import {
  DataImportColumnValues,
  DataJobHeader,
  DataImportJobResponse,
  DataImportPreviewRequest,
  DataImportProperties,
  DataImportValidateColumn,
  DataImportValidationResult,
  IngestImportFileResponse,
  ImportPreviewResponse,
} from '../models/DataImport';
import BaseService from './BaseService';

class DataImportService extends BaseService {
  public static ingestFile(file: File, progresCb: (progress: number) => void): Promise<ApiResponse<IngestImportFileResponse>> {
    return this.postFile('/v1/imports/ingest', 'file', file, progresCb);
  }

  public static getJobHeadings(jobId: string, headerRowIndex: number, sheetIndex = 0): Promise<ApiResponse<{ dataImportHeaders: DataJobHeader[] }>> {
    return this.get(`/v1/imports/${jobId}/import-headers`, { params: { headerRowIndex, sheetIndex } });
  }

  public static getColumnValues(jobId: string, columnIndex: number, sheetIndex: number): Promise<ApiResponse<DataImportColumnValues>> {
    return this.get(`/v1/imports/${jobId}/column-values/${columnIndex}`, { params: { sheetIndex } });
  }

  public static setJobHeadings(jobId: string, headers: DataJobHeader[]): Promise<ApiResponse<unknown>> {
    return this.post(`/v1/imports/${jobId}/import-headers`, headers);
  }

  public static setJobProperties(jobId: string, data: DataImportProperties): Promise<ApiResponse<unknown>> {
    return this.post(`/v1/imports/${jobId}/set-data-properties`, data);
  }

  public static startImport(jobId: string): Promise<ApiResponse<DataImportJobResponse>> {
    return this.post(`/v1/imports/start`, { importJobId: jobId });
  }

  public static reverse(jobId: string): Promise<ApiResponse<unknown>> {
    return this.post(`/v1/imports/${jobId}/reverse`);
  }

  public static getSheets(jobId: string): Promise<ApiResponse<Record<number, string>>> {
    return this.get(`/v1/imports/${jobId}/sheets`);
  }

  public static validateColumn(jobId: string, data: DataImportValidateColumn): Promise<ApiResponse<DataImportValidationResult>> {
    return this.post(`/v1/imports/${jobId}/validate`, data);
  }

  public static getRowPreview(jobId: string, data: DataImportPreviewRequest): Promise<ApiResponse<ImportPreviewResponse>> {
    return this.post(`/v1/imports/${jobId}/preview-row`, data);
  }
}

export default DataImportService;
