import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataImportWizard } from '../../../contexts/DataImportContext';
import { FormRendererMode } from '../../../contexts/FormRendererDesignContextTypes';
import { useModal } from '../../../contexts/ModalContext';
import { useProcessesHub } from '../../../contexts/signalR/ProcessesContext';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { EventSystem } from '../../../events/EventSystem';
import DataImportService from '../../../services/DataImportService';
import FormRendererV2 from '../../form/renderer/FormRendererV2';
import Button, { ButtonType } from '../form-control/Button';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import StandardModal from '../modal/variants/StandardModal';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';
import { ImportPreviewResponse } from '../../../models/DataImport';
import Tooltip from '../Tooltip';

const ImportPreviewStep: FC = () => {
  const { wizardState, restartFlow, prevStep, nextStep, stepNames } = useDataImportWizard();
  const { jobId, rowForData, sheetIndex } = wizardState;
  const { onClose: closeModal } = useModal();
  const toaster = useToasts();
  const { t } = useTranslation('data-import-export');
  const { listenToProcess } = useProcessesHub();
  const [saving, setSaving] = useState(false);

  const [previewForm, setPreviewForm] = useState<ImportPreviewResponse | null>(null);

  const [previewFormIndex, setPreviewFormIndex] = useState(0);

  useEffect(() => {
    setPreviewForm(null);

    DataImportService.getRowPreview(jobId as string, {
      targetRowIndex: previewFormIndex === 0 ? (rowForData ?? 0) - 1 : previewFormIndex,
      dataSheetIndex: sheetIndex,
    }).then((res) => {
      setPreviewForm(res.data);
    });
  }, [jobId, previewFormIndex, rowForData, sheetIndex]);

  const startImportProcess = useCallback(() => DataImportService.startImport(wizardState.jobId as string), [wizardState.jobId]);

  const waitForCompletion = useCallback(
    (processId: string) => {
      listenToProcess(processId)
        .then(() => {
          toaster.addToast({
            title: t('import.toasters.success.title'),
            description: t('import.toasters.success.description', { fileName: wizardState.file?.name }),
            type: ToastType.SUCCESS,
            slots: {
              button: (
                <Button
                  type={ButtonType.TERTIARY}
                  onClick={() => {
                    DataImportService.reverse(wizardState.jobId as string).then(() => {
                      EventSystem.fireEvent('data-import-done', null);
                    });
                  }}
                >
                  {t('import.toasters.success.button')}
                </Button>
              ),
            },
          });
        })
        .catch(() => {
          toaster.addToast({
            title: t('import.toasters.failure.title'),
            description: t('import.toasters.failure.description'),
            type: ToastType.ERROR,
            slots: {
              button: (
                <Button type={ButtonType.TERTIARY} onClick={restartFlow}>
                  {t('import.toasters.failure.button')}
                </Button>
              ),
            },
          });
        })
        .finally(() => EventSystem.fireEvent('data-import-done', null));
    },
    [listenToProcess, restartFlow, t, toaster, wizardState.file?.name, wizardState.jobId],
  );

  const onStartImportClicked = useCallback(() => {
    setSaving(true);
    startImportProcess().then((res) => {
      toaster.addToast({
        title: t('import.toasters.started.title'),
        description: t('import.toasters.started.description'),
        type: ToastType.INFO,
        expiresInMs: 3000,
      });

      closeModal && closeModal();
      waitForCompletion(res.data.jobId);
    });
  }, [closeModal, startImportProcess, t, toaster, waitForCompletion]);

  return (
    <StandardModal
      title={t('import.preview-step.heading')}
      subTitle={t('import.preview-step.subheading')}
      onCancelClick={prevStep}
      cancelButtonTitle={t('import.preview-step.buttons.cancel')}
      onConfirmClick={onStartImportClicked}
      confirmButtonTitle={t('import.preview-step.buttons.next')}
      confirmLoading={saving}
    >
      <WizardStepsIndicator activeStepIndex={3} stepNames={stepNames} onStepChange={nextStep} />
      <div className="relative min-h-80">
        <SkeletonLoader ready={!!previewForm} type="inputField" rows={5} cols>
          <div className="flex items-center justify-between gap-4">
            <Tooltip text={previewForm?.subtitle} truncatedTextMode>
              {(tooltip) => (
                <div {...tooltip} className="max-w-[80%] truncate font-medium">
                  {previewForm?.subtitle}

                  <ChevronIcon
                    type={ChevronType.LEFT}
                    className={`h-6 w-6 ${previewForm?.previousRowIndex === null ? 'text-gray-4' : 'text-black'}`}
                    onClick={previewForm?.previousRowIndex === null ? undefined : () => setPreviewFormIndex(previewForm?.previousRowIndex ?? 0)}
                  />
                  <ChevronIcon
                    type={ChevronType.RIGHT}
                    className={`h-6 w-6 ${previewForm?.nextRowIndex === null ? 'text-gray-4' : 'text-black'}`}
                    onClick={previewForm?.nextRowIndex === null ? undefined : () => setPreviewFormIndex(previewForm?.nextRowIndex ?? 0)}
                  />
                </div>
              )}
            </Tooltip>
            <div className="text-dpm-14">{t('import.preview-step.amount', { Rows: previewForm?.totalRecordCount ?? 0 })}</div>
          </div>
          <div className="max-h-[60vh] overflow-auto">
            {previewForm && (
              <FormRendererV2
                initialMode={FormRendererMode.PreviewView}
                clientForm={previewForm}
                featureToggles={{ disableModeToggle: true, disableAutoAnswerSubmit: true, disableQuestionCommentIcon: true }}
                renderAllSteps
              />
            )}
          </div>
        </SkeletonLoader>
      </div>
    </StandardModal>
  );
};

export default ImportPreviewStep;
