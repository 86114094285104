import { FC, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDataImportWizard } from '../../../contexts/DataImportContext';
import DataImportService from '../../../services/DataImportService';
import { FileInfo } from '../file-upload/FileInfo';
import UploadArea from '../file-upload/UploadArea';
import Button, { ButtonType } from '../form-control/Button';
import DownloadIcon from '../icon/DownloadIcon';
import FolderIcon from '../icon/FolderIcon';
import InfoIcon from '../icon/InfoIcon';
import TrashIcon from '../icon/TrashIcon';
import StandardModal from '../modal/variants/StandardModal';
import ProgressBar from '../ProgressBar';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';

const UploadFileStep: FC = () => {
  const { nextStep, cancelImport, wizardState, setWizardState, downloadExample, stepNames } = useDataImportWizard();
  const { jobId, file } = wizardState;
  const [fileUploadProgress, setFileUploadProgress] = useState(file ? 1 : 0);

  const { t } = useTranslation('data-import-export');

  const onUpload = useCallback(
    (fileInfo: FileInfo) => {
      setWizardState((prev) => ({ ...prev, file: fileInfo }));
      DataImportService.ingestFile(fileInfo.file, setFileUploadProgress).then((res) => {
        setWizardState((prev) => ({ ...prev, jobId: res.data.jobId }));
      });
    },
    [setWizardState],
  );

  return (
    <StandardModal
      title={t('import.upload-file-step.heading')}
      subTitle={t('import.upload-file-step.subheading')}
      tertiaryButtonTitle={t('import.upload-file-step.download-template')}
      onTertiaryButtonClick={downloadExample}
      tertiaryButtonIcon={<DownloadIcon className="h-6 w-6" />}
      cancelButtonTitle={t('import.upload-file-step.buttons.cancel')}
      onCancelClick={cancelImport}
      confirmButtonTitle={t('import.upload-file-step.buttons.next')}
      onConfirmClick={() => nextStep('selection')}
      confirmDisabled={!jobId}
    >
      <WizardStepsIndicator activeStepIndex={0} stepNames={stepNames} onStepChange={nextStep} />

      <div className="mt-4 min-h-80">
        <div className="w-full">
          {!file && (
            <UploadArea
              onUpload={(f) => onUpload(f[0])}
              acceptMimes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              {(triggerUploadDialogue) => (
                <div className="py-6 text-center text-black">
                  <div>{t('import.upload-file-step.upload-box.primary')}</div>
                  <div className="my-3">{t('import.upload-file-step.upload-box.or')}</div>
                  <div>
                    <Button type={ButtonType.TERTIARY} data-cy="browse-files" onClick={triggerUploadDialogue} className="text-link-1">
                      {t('import.upload-file-step.upload-box.browse')}
                    </Button>
                  </div>
                </div>
              )}
            </UploadArea>
          )}
          {file && (
            <div className="flex flex-col gap-1">
              <div className="flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <FolderIcon className="h-6 w-6" />
                  {file.name}
                </div>
                <div>
                  <TrashIcon
                    className="h-4 w-4"
                    onClick={() => {
                      // todo delete file from server?
                      setFileUploadProgress(0);
                      setWizardState((prev) => ({ ...prev, file: null, jobId: null }));
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="flex-grow">
                  <ProgressBar useProgressColours progress={fileUploadProgress} />
                </div>
                <div className="flex-shrink-0">{(fileUploadProgress * 100).toFixed(0)}%</div>
              </div>
              {file.mimeType === 'text/csv' && (
                <span>
                  <Trans t={t} i18nKey="import.upload-file-step.note.csv-no-subforms" components={{ Bold: <span className="font-medium" /> }} />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {!file && (
        <span className="text-dpm-14">
          <Trans
            t={t}
            i18nKey="import.upload-file-step.note.only-one-file"
            components={{ InfoIcon: <InfoIcon className="h-6 w-6" />, Bold: <span className="font-medium" /> }}
          />
        </span>
      )}
    </StandardModal>
  );
};

export default UploadFileStep;
