import { FC, useCallback, useMemo, useState } from 'react';
import { DataImportContext } from '../../../contexts/DataImportContext';
import { FileInfo } from '../file-upload/FileInfo';
import ImportPreviewStep from './ImportPreviewStep';
import MapColumnsStep from './MapColumnsStep';
import UploadFileStep from './UploadFileStep';
import SheetSelections from './SheetSelections';
import { useDownloadImportExample } from '../../../hooks/useDownloadImportExample';

export const importSteps = ['upload', 'selection', 'mapping', 'preview'] as const;
export type ImportStep = (typeof importSteps)[number];

type Props = {
  templateFormId: string;
  templateFormName: string;
  templateFormClient: string | null;
  onCancel: () => void;
  onRetry: () => void;
};

export type ImportWizardState = {
  file: FileInfo | null;
  jobId: string | null;
  rowForHeadings: number | null;
  rowForData: number | null;
  colForTitles: number | null;
  sheetIndex: number;
};

const DataImportWizard: FC<Props> = (props) => {
  const { templateFormId, templateFormName, templateFormClient, onCancel, onRetry } = props;

  const steps = useMemo<Record<ImportStep, JSX.Element>>(() => {
    return {
      upload: <UploadFileStep />,
      selection: <SheetSelections />,
      mapping: <MapColumnsStep />,
      preview: <ImportPreviewStep />,
    };
  }, []);

  const [wizardState, setWizardState] = useState<ImportWizardState>({
    file: null,
    jobId: null,
    rowForHeadings: null,
    rowForData: null,
    colForTitles: null,
    sheetIndex: 0,
  });

  const [stepStack, setStepStack] = useState([steps.upload]);
  const nextStep = useCallback(
    (name: ImportStep) => {
      setStepStack((prev) => [...prev, steps[name]]);
    },
    [steps],
  );

  const prevStep = useCallback(() => {
    setStepStack((prev) => prev.filter((_, i) => i !== prev.length - 1));
  }, []);

  const restartFlow = useCallback(() => {
    onRetry();
    setStepStack([steps.upload]);
  }, [onRetry, steps.upload]);

  const downloadExample = useDownloadImportExample();

  const downloadFile = useCallback(() => {
    downloadExample({ fileExtension: 'xlsx', templateId: templateFormId, templateName: templateFormName });
  }, [downloadExample, templateFormId, templateFormName]);

  const contextValues = useMemo(
    () => ({
      templateFormId,
      templateFormClient,
      wizardState,
      setWizardState,
      nextStep,
      prevStep,
      cancelImport: onCancel,
      restartFlow,
      downloadExample: downloadFile,
      stepNames: importSteps,
    }),
    [downloadFile, nextStep, onCancel, prevStep, restartFlow, templateFormClient, templateFormId, wizardState],
  );

  return <DataImportContext.Provider value={contextValues}>{stepStack[stepStack.length - 1]}</DataImportContext.Provider>;
};

export default DataImportWizard;
