import { createContext, useContext } from 'react';
import { ImportWizardState, ImportStep } from '../components/shared/data-import/DataImportWizard';

type DataImportContext = {
  templateFormId: string;
  templateFormClient: string | null;
  wizardState: ImportWizardState;
  setWizardState: (value: ImportWizardState | ((prev: ImportWizardState) => ImportWizardState)) => void;
  cancelImport: () => void;
  nextStep: (stepName: ImportStep) => void;
  prevStep: () => void;
  restartFlow: () => void;
  downloadExample: () => void;
  stepNames: readonly ImportStep[];
};

export const DataImportContext = createContext<DataImportContext>(null!);
export const useDataImportWizard = (): DataImportContext => useContext(DataImportContext);
