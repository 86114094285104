import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataImportWizard } from '../../../contexts/DataImportContext';
import { DataJobHeader, DataImportValidationResult, ImportAction } from '../../../models/DataImport';
import { FormConfig } from '../../../models/Form';
import ClientTemplateFormService from '../../../services/ClientTemplateFormService';
import DataImportService from '../../../services/DataImportService';
import TemplateFormService from '../../../services/TemplateFormService';
import StandardModal from '../modal/variants/StandardModal';
import Tooltip from '../Tooltip';
import ColumnMapperRow from './ColumnMapperRow';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import ActionTypes from '../../form/ActionTypes';

const MapColumnsStep: FC = () => {
  const { templateFormId, templateFormClient, wizardState, prevStep, nextStep, stepNames } = useDataImportWizard();
  const { jobId, rowForHeadings, sheetIndex } = wizardState;

  const [jobHeaders, setJobHeaders] = useState<DataJobHeader[]>([]);
  const [templateForm, setTemplateForm] = useState<FormConfig | null>(null);
  const [mappings, setMappings] = useState<Record<string, number>>({});
  const [valueMappings, setValueMappings] = useState<
    Record<string, Record<'yes' | 'no' | 'na' | 'currencyCode' | 'phoneCountryCode', [string | null]>>
  >({});
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const [validated, setValidated] = useState(false);
  const [validationErrors, setValidationErrors] = useState<DataImportValidationResult>({});
  const [isFetchingTemplate, setIsFetchingTemplate] = useState(false);
  const [isFetchingHeaders, setIsFetchingHeaders] = useState(false);

  const { t, i18n } = useTranslation('data-import-export');

  useEffect(() => {
    setIsFetchingTemplate(true);
    (templateFormClient ? new ClientTemplateFormService(templateFormClient) : TemplateFormService)
      .getFormTemplate(templateFormId)
      .then((res) => {
        setTemplateForm(res.data);
      })
      .finally(() => setIsFetchingTemplate(false));
  }, [templateFormClient, templateFormId]);

  useEffect(() => {
    setIsFetchingHeaders(true);
    DataImportService.getJobHeadings(wizardState.jobId || '', (wizardState.rowForHeadings || 1) - 1, wizardState.sheetIndex)
      .then((res) => {
        setJobHeaders(res.data.dataImportHeaders.sort((a, b) => a.columnIndex - b.columnIndex));
      })
      .finally(() => setIsFetchingHeaders(false));
  }, [wizardState.colForTitles, wizardState.jobId, wizardState.rowForHeadings, wizardState.sheetIndex]);

  const mapRowInfo = useMemo(() => {
    const result = [];

    for (const section of templateForm?.sections || []) {
      for (const action of section.actions) {
        if (action.noninteractive) continue;

        result.push({
          sectionId: section.id,
          action: action,
          templateQuestionTitle: ActionTypes[action.type].actionTitle(action, i18n.language) || '',
          mappedToIndex: mappings?.[action.id] ?? null,
        });
      }
    }

    return result;
  }, [i18n.language, mappings, templateForm?.sections]);

  const nextDisabled = useMemo(() => {
    return mapRowInfo.every((x) => x.mappedToIndex === null) || Object.values(errors).some((x) => x);
  }, [errors, mapRowInfo]);

  // Memoize the callbacks to make sure they don't change across re-renders
  const updateValueMaps = useMemo(() => {
    const cbs: Record<
      string,
      (yesValue: string | null, noValue: string | null, naValue: string | null, phoneValue: string | null, currencyValue: string | null) => void
    > = {};
    for (const row of mapRowInfo) {
      cbs[row.action.id] = (
        yesValue: string | null,
        noValue: string | null,
        naValue: string | null,
        phoneValue: string | null,
        currencyValue: string | null,
      ) => {
        setValueMappings((prev) => ({
          ...prev,
          [row.action.id]: { yes: [yesValue], no: [noValue], na: [naValue], currencyCode: [currencyValue], phoneCountryCode: [phoneValue] },
        }));
      };
    }

    return cbs;
  }, [mapRowInfo]);

  const saveMapping = useCallback(
    (ignoreValidation = false) => {
      setSaving(true);

      const mapping: DataJobHeader[] = jobHeaders.map((header) => {
        const row = mapRowInfo.find((x) => x.mappedToIndex === header.columnIndex);
        return {
          ...header,
          actionId: row?.action.id || null,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actionType: row?.action.type || ('' as any), // BE requires a not null value
          sectionId: row?.sectionId || null,
          dataAction: row ? ImportAction.Map : ImportAction.Ignore,
          yesValues: valueMappings?.[row?.action.id || '']?.yes.filter(Boolean) ?? [],
          noValues: valueMappings?.[row?.action.id || '']?.no.filter(Boolean) ?? [],
          notApplicableValues: valueMappings?.[row?.action.id || '']?.na.filter(Boolean) ?? [],
          countryCode: valueMappings?.[row?.action.id || '']?.phoneCountryCode?.[0] ?? null,
          currencyCode: valueMappings?.[row?.action.id || '']?.currencyCode?.[0] ?? null,
        };
      });

      if (ignoreValidation === false && !validated) {
        DataImportService.validateColumn(jobId as string, {
          templateFormId: templateFormId,
          dataRowIndex: rowForHeadings as number,
          dataSheetIndex: sheetIndex,
          headers: mapping,
        }).then((res) => {
          setValidationErrors(res.data);
          const isValid = Object.values(res.data).every((x) => x.every((e) => e.isSuccess));
          setValidated(isValid);
          if (isValid) {
            saveMapping(true);
          } else {
            setSaving(false);
          }
        });
        return;
      }

      DataImportService.setJobHeadings(wizardState.jobId as string, mapping)
        .then(() => {
          nextStep('preview');
        })
        .finally(() => {
          setSaving(false);
        });
    },
    [jobHeaders, jobId, mapRowInfo, nextStep, rowForHeadings, sheetIndex, templateFormId, validated, valueMappings, wizardState.jobId],
  );

  // Memoize the callbacks to make sure they don't change across re-renders
  const mapToIndex = useMemo(() => {
    const cbs: Record<string, (index: number) => void> = {};
    for (const row of mapRowInfo) {
      cbs[row.action.id] = (index: number) => {
        setMappings((prev) => ({ ...prev, [row.action.id]: index }));
      };
    }

    return cbs;
  }, [mapRowInfo]);

  // Memoize the callbacks to make sure they don't change across re-renders
  const hasError = useMemo(() => {
    const cbs: Record<string, (error: boolean) => void> = {};
    for (const row of mapRowInfo) {
      cbs[row.action.id] = (error: boolean) => {
        setErrors((prev) => ({ ...prev, [row.action.id]: error }));
      };
    }

    return cbs;
  }, [mapRowInfo]);

  // Memoize the callbacks to make sure they don't change across re-renders
  const clearValidation = useMemo(() => {
    const cbs: Record<number, () => void> = {};
    for (const row of mapRowInfo) {
      cbs[row.mappedToIndex] = () => {
        setValidationErrors((prev) => {
          const { [row.mappedToIndex]: _, ...rest } = prev;
          return rest;
        });
      };
    }

    return cbs;
  }, [mapRowInfo]);

  const alreadyMappedColumns = useMemo(() => Object.values(mappings), [mappings]);

  return (
    <StandardModal
      title={t('import.mapping-step.heading')}
      subTitle={t('import.mapping-step.subheading', { fileName: wizardState.file?.name })}
      onCancelClick={prevStep}
      cancelButtonTitle={t('import.mapping-step.buttons.cancel')}
      onConfirmClick={() => saveMapping()}
      confirmButtonTitle={t('import.mapping-step.buttons.next')}
      confirmDisabled={nextDisabled}
      confirmLoading={saving}
    >
      <WizardStepsIndicator activeStepIndex={2} stepNames={stepNames} onStepChange={nextStep} />
      <div className="min-h-80">
        <SkeletonLoader ready={!(isFetchingTemplate || isFetchingHeaders)} type="inputField" rows={5} cols>
          <div className="flex flex-col gap-2">
            <div className="sticky -top-5 z-50 flex items-center gap-4 bg-white py-3 text-black">
              <div className="flex-1 font-medium">{t('import.mapping-step.table.heading.template-title')}</div>
              <div className="flex w-[350px] items-center justify-between gap-4">
                <Tooltip text={wizardState.file?.name} truncatedTextMode>
                  {(tooltip) => (
                    <span {...tooltip} className="truncate font-medium">
                      {wizardState.file?.name}
                    </span>
                  )}
                </Tooltip>
                <span className="text-dpm-14 flex-shrink-0">{t('import.mapping-step.table.heading.total-items', { number: mapRowInfo.length })}</span>
              </div>
            </div>

            {mapRowInfo.map((x, i) => (
              <ColumnMapperRow
                key={i}
                fileHeaders={jobHeaders}
                mapToIndex={mapToIndex[x.action.id]}
                updateValueMaps={updateValueMaps[x.action.id]}
                hasError={hasError[x.action.id]}
                otherMappedColumns={alreadyMappedColumns}
                validationErrors={validationErrors[x.mappedToIndex]}
                clearValidation={clearValidation[x.mappedToIndex]}
                {...x}
              />
            ))}
          </div>
        </SkeletonLoader>
      </div>
    </StandardModal>
  );
};

export default MapColumnsStep;
