import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataImportFailureMessage } from '../../../models/DataImport';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';

const ColumnError: FC<{ errors: DataImportFailureMessage[] }> = (props) => {
  const { errors } = props;
  const [errorIndex, setErrorIndex] = useState(0);
  const { t } = useTranslation(['data-import-export', 'form-builder']);

  return (
    <div>
      <div className="font-medium text-red-500">{t('data-import-export:import.mapping-step.column-errors.heading', { Amount: errors.length })}</div>
      <div className="text-dpm-14 text-red-500">
        {t('data-import-export:import.mapping-step.column-errors.row-error', {
          RowNumber: errors[errorIndex].row + 1,
          Value: errors[errorIndex].answer,
          ActionType: t(`form-builder:action-types.${errors[errorIndex].actionType}`),
        })}
      </div>
      <div className="flex items-center gap-2">
        <ChevronIcon
          type={ChevronType.LEFT}
          className={`h-5 w-5 ${errorIndex === 0 ? 'text-gray-2' : 'text-black'}`}
          onClick={errorIndex === 0 ? undefined : () => setErrorIndex((prev) => prev - 1)}
        />
        <div className="text-black">{errorIndex + 1}</div>
        <ChevronIcon
          type={ChevronType.RIGHT}
          className={`h-5 w-5 ${errorIndex === errors.length - 1 ? 'text-gray-2' : 'text-black'}`}
          onClick={errorIndex === errors.length - 1 ? undefined : () => setErrorIndex((prev) => prev + 1)}
        />
      </div>
    </div>
  );
};

export default ColumnError;
